.paper-shadow {
  background: #FFFFFF;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin: 2px !important;
}

.fadeIn {
  opacity: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.fadeArea:hover .fadeIn {
  opacity: .4;
  transition: .30s;
  transition-delay: .2s;
}

.fadeArea:hover .fadeIn:hover {
  opacity: 1;
  transition: .5s;
  transition-delay: 0s;
}

/* Typography */

.title-page {
  font-family: Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 15.4875pt;
  letter-spacing: 0;
  text-align: left;
}
